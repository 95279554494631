export const DOMAIN = `TicketTemplateDetails`

export const DELETE_TICKET_TEMPLATE = `app/container/TicketTemplateDetailsPage/DELETE_TICKET_TEMPLATE`
export const DELETE_TICKET_TEMPLATE_ERROR = `app/container/TicketTemplateDetailsPage/DELETE_TICKET_TEMPLATE_ERROR`
export const DELETE_TICKET_TEMPLATE_SUCCESS = `app/container/TicketTemplateDetailsPage/DELETE_TICKET_TEMPLATE_SUCCESS`
export const DELETE_TICKET_TEMPLATE_RESET = `app/container/TicketTemplateDetailsPage/DELETE_TICKET_TEMPLATE_RESET`

export const UPDATE_TICKET_TEMPLATE = `app/container/TicketTemplateDetailsPage/UPDATE_TICKET_TEMPLATE`
export const UPDATE_TICKET_TEMPLATE_ERROR = `app/container/TicketTemplateDetailsPage/UPDATE_TICKET_TEMPLATE_ERROR`
export const UPDATE_TICKET_TEMPLATE_SUCCESS = `app/container/TicketTemplateDetailsPage/UPDATE_TICKET_TEMPLATE_SUCCESS`
export const UPDATE_TICKET_TEMPLATE_RESET = `app/container/TicketTemplateDetailsPage/UPDATE_TICKET_TEMPLATE_RESET`

export const FETCH_TICKET_TEMPLATE_DETAILS = `app/container/TicketTemplateDetailsPage/FETCH_TICKET_TEMPLATE_DETAILS`
export const FETCH_TICKET_TEMPLATE_DETAILS_ERROR = `app/container/TicketTemplateDetailsPage/FETCH_TICKET_TEMPLATE_DETAILS_ERROR`
export const FETCH_TICKET_TEMPLATE_DETAILS_SUCCESS = `app/container/TicketTemplateDetailsPage/FETCH_TICKET_TEMPLATE_DETAILS_SUCCESS`
