import { createAction } from 'redux-actions'
import {
  DELETE_TICKET_TEMPLATE,
  DELETE_TICKET_TEMPLATE_ERROR,
  DELETE_TICKET_TEMPLATE_SUCCESS,
  FETCH_TICKET_TEMPLATE_DETAILS,
  FETCH_TICKET_TEMPLATE_DETAILS_ERROR,
  FETCH_TICKET_TEMPLATE_DETAILS_SUCCESS,
  DELETE_TICKET_TEMPLATE_RESET,
  UPDATE_TICKET_TEMPLATE,
  UPDATE_TICKET_TEMPLATE_ERROR,
  UPDATE_TICKET_TEMPLATE_SUCCESS,
  UPDATE_TICKET_TEMPLATE_RESET,
} from './constants'

export const deleteTicketTemplate = createAction(DELETE_TICKET_TEMPLATE)
export const deleteTicketTemplateError = createAction(
  DELETE_TICKET_TEMPLATE_ERROR
)
export const deleteTicketTemplateSuccess = createAction(
  DELETE_TICKET_TEMPLATE_SUCCESS
)
export const deleteTicketTemplateReset = createAction(
  DELETE_TICKET_TEMPLATE_RESET
)

export const fetchTicketTemplateDetails = createAction(
  FETCH_TICKET_TEMPLATE_DETAILS
)
export const fetchTicketTemplateDetailsError = createAction(
  FETCH_TICKET_TEMPLATE_DETAILS_ERROR
)
export const fetchTicketTemplateDetailsSuccess = createAction(
  FETCH_TICKET_TEMPLATE_DETAILS_SUCCESS
)

export const updateTicketTemplate = createAction(UPDATE_TICKET_TEMPLATE)
export const updateTicketTemplateError = createAction(
  UPDATE_TICKET_TEMPLATE_ERROR
)
export const updateTicketTemplateSucces = createAction(
  UPDATE_TICKET_TEMPLATE_SUCCESS
)
export const updateTicketTemplateReset = createAction(
  UPDATE_TICKET_TEMPLATE_RESET
)
