import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import _ from 'lodash'

import styled from 'styled-components'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import {
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from 'reactstrap'
import Button from 'components/Button'
import Icon from 'components/Icon'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import TextLink from 'components/TextLink'
import TemplateModalError from './TemplateModalError'

import messages from './messages'
import { checkURL } from './utils'

const FormContainer = styled.div`
  .input-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

const validateSourceURI = (value, setIsValidSourceURI) => {
  const isValid = checkURL(value)
  setIsValidSourceURI(isValid)
  return isValid
}

const throttleValidate = _.throttle(validateSourceURI, 2000, {
  trailing: true,
})

/**
 * Component for create/edit of ticket templates
 */
export function CreateTicketTemplateModal(props) {
  const [sourceURIValue, setSourceURIValue] = useState('')
  const [sourceURIValidation, setSourceURIValidation] = useState({
    isValid: true,
    isWarn: false,
    message: null,
  })

  // This is the code that will close the modal after the user saves if there
  // are no errors.
  const prevMutateStatusRef = useRef()
  useEffect(() => {
    if (prevMutateStatusRef.current) {
      const wasLoading = prevMutateStatusRef.current.get('loading')
      const isLoading = props.mutateTicketTempaltesStatus.get('loading')
      const justFinishedLoading = wasLoading && !isLoading
      if (
        justFinishedLoading &&
        !props.mutateTicketTempaltesStatus.get('errors')
      ) {
        props.close()
      }
    }
    prevMutateStatusRef.current = props.mutateTicketTempaltesStatus
  }, [props.mutateTicketTempaltesStatus])

  // onChange = set state and throttle validate
  const handleSourceURIChange = ({ target: { value } }) => {
    setSourceURIValue(value)
    throttleValidate(value, setSourceURIValidation)
  }
  // onBlur = validate immediately
  const handleSourceURIBlur = () =>
    validateSourceURI(sourceURIValue, setSourceURIValidation)

  function handleSaveClick() {
    const isValid = validateSourceURI(sourceURIValue, setSourceURIValidation)
    if (isValid) {
      props.onSave({ sourceURI: sourceURIValue })
    }
  }

  return (
    <Modal
      isOpen={props.isOpen}
      size="lg"
      onClosed={() => {
        setSourceURIValue('')
        props.mutateticketTemplateResetError()
      }}
    >
      <ModalHeader>
        <DynamicFormattedMessage
          {...messages[
            props.update ? 'updateModalHeader' : 'createModalHeader'
          ]}
        />
      </ModalHeader>
      <ModalBody>
        <FormContainer>
          <Label for="sourceURI">
            <DynamicFormattedMessage {...messages.sourceURI} />
          </Label>
          <div className="input-container">
            <Input
              id="sourceURI"
              onBlur={handleSourceURIBlur}
              onChange={handleSourceURIChange}
              value={sourceURIValue}
              invalid={!sourceURIValidation.isValid}
              style={{
                border: sourceURIValidation.isWarn
                  ? `1px solid ${props.theme.warn}`
                  : undefined,
              }}
            />
          </div>
          <TemplateModalError
            sourceURIValidation={sourceURIValidation}
            mutateTicketTempaltesStatus={props.mutateTicketTempaltesStatus}
          />
        </FormContainer>
      </ModalBody>
      <ModalFooter>
        <TextLink color="primary" onClick={props.close}>
          <DynamicFormattedMessage {...messages.cancel} />
        </TextLink>
        <Button
          color="primary"
          onClick={handleSaveClick}
          disabled={props.mutateTicketTempaltesStatus.get('loading')}
        >
          <DynamicFormattedMessage {...messages.save} />
          {props.mutateTicketTempaltesStatus.get('loading') && (
            <Icon fa name="sync" spin />
          )}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

CreateTicketTemplateModal.defaultProps = {
  update: false,
}

CreateTicketTemplateModal.propTypes = {
  close: PropTypes.func.isRequired,
  mutateticketTemplateResetError: PropTypes.func.isRequired,
  mutateTicketTempaltesStatus: ImmutablePropTypes.contains({
    errors: ImmutablePropTypes.listOf(PropTypes.string),
  }),
  isOpen: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  update: PropTypes.bool, // whether to show "Create" or "Update" messages
  theme: themeShape,
}

export default themeable(CreateTicketTemplateModal)
