import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import {
  DELETE_TICKET_TEMPLATE,
  DELETE_TICKET_TEMPLATE_ERROR,
  DELETE_TICKET_TEMPLATE_SUCCESS,
  DELETE_TICKET_TEMPLATE_RESET,
  FETCH_TICKET_TEMPLATE_DETAILS,
  FETCH_TICKET_TEMPLATE_DETAILS_ERROR,
  FETCH_TICKET_TEMPLATE_DETAILS_SUCCESS,
  UPDATE_TICKET_TEMPLATE,
  UPDATE_TICKET_TEMPLATE_ERROR,
  UPDATE_TICKET_TEMPLATE_SUCCESS,
  UPDATE_TICKET_TEMPLATE_RESET,
} from './constants'

export const initialState = fromJS({
  deleteTicketTemplate: {
    loading: false,
    error: false,
  },
  ticketTemplateDetails: {
    loading: false,
    error: false,
    data: null,
  },
  updateTicketTemplate: {
    loading: false,
    errors: null,
  },
})

const reducer = handleActions(
  {
    // delete ticket template:
    [DELETE_TICKET_TEMPLATE]: state =>
      state
        .setIn(['deleteTicketTemplate', 'loading'], true)
        .setIn(['deleteTicketTemplate', 'error'], false),
    [DELETE_TICKET_TEMPLATE_ERROR]: state =>
      state
        .setIn(['deleteTicketTemplate', 'loading'], false)
        .setIn(['deleteTicketTemplate', 'error'], true),
    [DELETE_TICKET_TEMPLATE_SUCCESS]: state =>
      state
        .setIn(['deleteTicketTemplate', 'loading'], false)
        .setIn(['deleteTicketTemplate', 'error'], false),
    [DELETE_TICKET_TEMPLATE_RESET]: state =>
      state
        .setIn(['deleteTicketTemplate', 'loading'], false)
        .setIn(['deleteTicketTemplate', 'error'], false),

    // fetch ticket template:
    [FETCH_TICKET_TEMPLATE_DETAILS]: state =>
      state
        .setIn(['ticketTemplateDetails', 'loading'], true)
        .setIn(['ticketTemplateDetails', 'error'], false),
    [FETCH_TICKET_TEMPLATE_DETAILS_ERROR]: state =>
      state
        .setIn(['ticketTemplateDetails', 'loading'], false)
        .setIn(['ticketTemplateDetails', 'error'], true),
    [FETCH_TICKET_TEMPLATE_DETAILS_SUCCESS]: (state, { payload }) =>
      state
        .setIn(['ticketTemplateDetails', 'loading'], false)
        .setIn(['ticketTemplateDetails', 'error'], false)
        .setIn(['ticketTemplateDetails', 'data'], fromJS(payload.data)),

    // update ticket template
    [UPDATE_TICKET_TEMPLATE]: state =>
      state
        .setIn(['updateTicketTemplate', 'loading'], true)
        .setIn(['updateTicketTemplate', 'errors'], null),
    [UPDATE_TICKET_TEMPLATE_ERROR]: (state, { payload }) =>
      state
        .setIn(['updateTicketTemplate', 'loading'], false)
        .setIn(['updateTicketTemplate', 'errors'], fromJS(payload)),
    [UPDATE_TICKET_TEMPLATE_RESET]: state =>
      state.setIn(['updateTicketTemplate', 'errors'], null),
    [UPDATE_TICKET_TEMPLATE_SUCCESS]: state =>
      state
        .setIn(['updateTicketTemplate', 'loading'], false)
        .setIn(['updateTicketTemplate', 'errors'], null),
  },
  initialState
)

export default reducer
