import _ from 'lodash'
import { isValidURL } from 'utils/sonraiUtils'

export function checkURL(url) {
  const isURLFormatted = isValidURL(url)
  if (!isURLFormatted) {
    return { isValid: false, isWarn: false, message: 'invalid.url' }
  }
  if (isURLFormatted) {
    const endOfStr = url.substr(url.lastIndexOf('/') + 1)
    const hasFileExt = endOfStr.includes('.')
    if (hasFileExt) {
      if (endOfStr === 'manifest.yaml') {
        return { isValid: true }
      } else {
        return { isValid: false, isWarn: false, message: 'invalid.filename' }
      }
    } else {
      return { isValid: true, isWarn: true, message: 'filename.warn' }
    }
  }
}

const ERROR_MESSAGE_REGEX = /^Exception while fetching data \(\/(Create|Update)TicketTemplate\) : Invalid Manifest:/

/**
 * turn the error message from apollo client into something that is useful to
 * display error to user, e.g
 *
 * @param {stirng} message "Exception while fetching data (/CreateTicketTemplate) : Invalid Manifest:{"fields.[3].type.required":"field 3 type is required","fields.[1].select.required":"field 1 must have definition of select","fields.[2].select.required":"field 2 must have definition of select","fields.[4].type.invalid":"field 4 has invalid type. types allowed are 'text', 'checkbox', 'boolean'","title.required":"title is required","fields.[2].name.required":"field 2 name is required"}"
 * @returns {string[]}
 * Object.key({
 *   "fields.[3].type.required": "field 3 type is required",
 *   "fields.[1].select.required": "field 1 must have definition of select",
 *   "fields.[2].select.required": "field 2 must have definition of select",
 *   "fields.[4].type.invalid": "field 4 has invalid type. types allowed are 'text', 'checkbox', 'boolean'",
 *   "title.required": "title is required",
 *   "fields.[2].name.required": "field 2 name is required"
 * })
 *
 * aka ["fields.[3].type.required", ...]
 */
export function processBotValidationError({ message }) {
  if (!message.match(ERROR_MESSAGE_REGEX)) {
    throw new Error(`unprocessable message: \`${message}\``)
  }

  const messageWithoutPrefix = message.replace(ERROR_MESSAGE_REGEX, '')

  try {
    const errors = JSON.parse(messageWithoutPrefix)
    return Object.keys(errors)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('error processing error message', e)
    throw new Error(`unprocessable message: \`${message}\``)
  }
}

/**
 * the error messages returned from the endpoint will be like 'fields.[2].select.required'
 *
 * we want to transform them to look like:
 * {
 *   key: 'fields.select.requried',
 *   values: { position: '2' },
 * }
 */
export function createErrorMessageArgs(messageKey) {
  const values = {}
  const key = messageKey
    .split('.')
    .filter(segment => {
      // try to parse the segment like '[2]' and if it parses, position is number
      try {
        const position = JSON.parse(segment)
        if (
          _.isArray(position) &&
          position.length > 0 &&
          _.isNumber(position[0])
        ) {
          values.position = position[0]
          return false
        }
        // eslint-disable-next-line no-empty
      } catch (e) {}

      // did not parse, it must be part of key:
      return true
    })
    .join('.')

  return { key, values }
}
