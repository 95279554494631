import _ from 'lodash'
import qs from 'query-string'
import { DOMAIN } from './constants'
import { createSelector } from 'reselect'
import { initialState } from './reducers'

export const selectQueryStringSrn = (state, props) => {
  return qs.parse(_.get(props, ['location', 'search'], '')).srn
}

export const selectDomain = state => state.get(DOMAIN) ?? initialState

export const selectTicketTemplateDetails = createSelector(selectDomain, state =>
  state.get('ticketTemplateDetails')
)

export const selectDeleteTicketTemplate = createSelector(selectDomain, state =>
  state.get('deleteTicketTemplate')
)

export const selectUpdateTicketTemplate = createSelector(selectDomain, state =>
  state.get('updateTicketTemplate')
)
