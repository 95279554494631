import { defineMessages } from 'react-intl'

export default defineMessages({
  breadcrumbItemAllTemplates: {
    id: 'app.containers.TicketTemplateDetailsPage.breadcrumbItemAllTemplates',
    defaultMessage: 'All Ticket Templates',
  },
  breadcrumbItemsTemplateDetails: {
    id:
      'app.containers.TicketTemplateDetailsPage.breadcrumbItemsTemplateDetails',
    defaultMessage: 'Ticket Template Details',
  },
  cancel: {
    id: 'app.containers.TicketTemplateDetailsPage.cancel',
    defaultMessage: 'Cancel',
  },
  confirm: {
    id: 'app.containers.TicketTemplateDetailsPage.confirm',
    defaultMessage: 'Confirm',
  },
  deleteConfirmModalBody: {
    id: 'app.containers.TicketTemplateDetailsPage.deleteConfirmModalBody',
    defaultMessage:
      'Are you sure you would like to delete this custom ticket template?',
  },
  deleteConfirmModalTitle: {
    id: 'app.containers.TicketTemplateDetailsPage.deleteConfirmModalTitle',
    defaultMessage: 'Confirm Delete',
  },
  deleteError: {
    id: 'app.containers.TicketTemplateDetailsPage.deleteError',
    defaultMessage: 'There was an error deleting custom ticket template.',
  },
  dropdownTextDelete: {
    id: 'app.containers.TicketTemplateDetailsPage.dropdownTextDelete',
    defaultMessage: 'Delete',
  },
  dropdownTextUpdate: {
    id: 'app.containers.TicketTemplateDetailsPage.dropdownTextUpdate',
    defaultMessage: 'Update',
  },
  errorLoadingTicketTemplateData: {
    id:
      'app.containers.TicketTemplateDetailsPage.errorLoadingTicketTemplateData',
    defaultMessage: 'There was an error loading custom ticket template details',
  },
  labelAuthorName: {
    id: 'app.containers.TicketTemplateDetailsPage.labelAuthorName',
    defaultMessage: 'Author Name',
  },
  labelAuthorEmail: {
    id: 'app.containers.TicketTemplateDetailsPage.labelAuthorEmail',
    defaultMessage: 'Author Email',
  },
  labelDescription: {
    id: 'app.containers.TicketTemplateDetailsPage.labelDescription',
    defaultMessage: 'Description',
  },
  labelLastModified: {
    id: 'app.containers.TicketTemplateDetailsPage.labelLastModified',
    defaultMessage: 'Last Modified',
  },
  labelOwner: {
    id: 'app.containers.TicketTemplateDetailsPage.labelOwner',
    defaultMessage: 'Owner',
  },
  labelSource: {
    id: 'app.containers.TicketTemplateDetailsPage.labelSource',
    defaultMessage: 'Source',
  },
  labelSourceContent: {
    id: 'app.containers.TicketTemplateDetailsPage.labelSourceContent',
    defaultMessage: 'Source Content',
  },
  labelTitle: {
    id: 'app.containers.TicketTemplateDetailsPage.labelTitle',
    defaultMessage: 'Title',
  },
  labelURL: {
    id: 'app.containers.TicketTemplateDetailsPage.labelURL',
    defaultMessage: 'URL',
  },
  labelVersion: {
    id: 'app.containers.TicketTemplateDetailsPage.labelVersion',
    defaultMessage: 'Version',
  },
  usedInTickets: {
    id: 'app.containers.TicketTemplateDetailsPage.usedInTickets',
    defaultMessage: 'used in {ticketCount} tickets',
  },
})
