import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'

import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import { DAEMON } from 'utils/constants'
import reducer from './reducers'
import sagas from './sagas'
import { DOMAIN } from './constants'

import {
  deleteTicketTemplate,
  deleteTicketTemplateReset,
  fetchTicketTemplateDetails,
  updateTicketTemplate,
  updateTicketTemplateReset,
} from './actions'
import {
  selectDeleteTicketTemplate,
  selectQueryStringSrn,
  selectTicketTemplateDetails,
  selectUpdateTicketTemplate,
} from './selectors'

import styled from 'styled-components'
import WithPermission from 'containers/PermissionChecker/WithPermission'
import BarLoadingAnimation from 'components/BarLoadingAnimation'
import Breadcrumb, { BreadcrumbItem } from 'components/Breadcrumb'
import BorderedCard from 'components/BorderedCard'
import Dropdown, {
  DropdownMenu,
  DropdownAnchor,
  DropdownItem,
} from 'components/Dropdown'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import Icon from 'components/Icon'
import { NodeViewTabs } from 'components/NodeView'
import SectionHeader from 'components/SectionHeader'
import TextLink from 'components/TextLink'
import TicketTemplateSourceURIModal from 'components/TicketTemplates/TicketTemplateSourceURIModal'
import DeleteTicketTemplatePromptModal from './components/DeleteTicketTemplatePromptModal'
import ManifestDetails from './components/TicketTemplateManifestDetails'
import Properties from './components/TicketTemplateProperties'

import messages from './messages'

const PageContainer = styled.div`
  padding: 16px;
  height: 100%;
  overflow: auto;

  .actions-dropdown {
    position: absolute;
    right: 16px;
    top: 16px;
  }

  .bread-crumb-container {
    margin-bottom: 8px;
  }

  .content {
    margin-top: 16px;
  }

  .dropdown-anchor {
    color: gray;
  }
`

export class TicketTemplateDetailsPage extends Component {
  constructor(props, context) {
    super(props, context)
    this.props.fetchTicketTemplateDetails({ srn: props.queryStringSrn })
    this.state = {
      deleteModalOpen: false,
      updateModalOpen: false,
    }
  }

  componentDidUpdate(prevProps) {
    // close delete modal if finished deleting
    if (
      prevProps.deleteStatus.get('loading') &&
      !this.props.deleteStatus.get('loading')
    ) {
      if (!this.props.deleteStatus.get('error')) {
        this.closeDeleteModal()
        this.props.push({ pathname: '/App/TicketTemplates' })
      }
    }
  }

  openDeleteModal = () => {
    this.setState({ deleteModalOpen: true })
  }

  closeDeleteModal = () => {
    this.setState({ deleteModalOpen: false })
    this.props.deleteTicketTemplateReset() // reset state (the error message)
  }

  openUpdateModal = () => {
    this.setState({ updateModalOpen: true })
  }

  closeUpdateModal = () => {
    this.setState({ updateModalOpen: false })
  }

  onConfirmDelete = () => {
    this.props.deleteTicketTemplate({
      srn: this.props.ticketTemplateDetails.getIn(['data', 'srn']),
    })
  }

  onSaveClick = ({ sourceURI }) => {
    this.props.updateTicketTemplate({
      sourceURI,
      srn: this.props.ticketTemplateDetails.getIn(['data', 'srn']),
    })
  }

  render() {
    if (this.props.ticketTemplateDetails.get('loading')) {
      return <BarLoadingAnimation />
    }

    if (this.props.ticketTemplateDetails.get('error')) {
      return (
        <DynamicFormattedMessage {...messages.errorLoadingTicketTemplateData} />
      )
    }

    const ticketCount = this.props.ticketTemplateDetails.getIn([
      'data',
      'ticketCount',
    ])

    return (
      <PageContainer>
        <div className="bread-crumb-container">
          <Breadcrumb>
            <BreadcrumbItem>
              <TextLink color="primary" to="/App/TicketTemplates">
                <DynamicFormattedMessage
                  {...messages.breadcrumbItemAllTemplates}
                />
              </TextLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <DynamicFormattedMessage
                {...messages.breadcrumbItemsTemplateDetails}
              />
            </BreadcrumbItem>
          </Breadcrumb>
        </div>
        <BorderedCard>
          <SectionHeader>
            {this.props.ticketTemplateDetails.getIn(['data', 'title'])}
          </SectionHeader>
          <div className="actions-dropdown">
            <WithPermission
              permissionName="edit.tickettemplates"
              resourceId={this.props.ticketTemplateDetails.getIn([
                'data',
                'resourceId',
              ])}
            >
              <Dropdown>
                <DropdownAnchor borderless>
                  <span className="dropdown-anchor">
                    <Icon fa name="cog-solid" />
                  </span>
                </DropdownAnchor>
                <DropdownMenu>
                  <DropdownItem
                    label={
                      <span
                        style={{
                          color: ticketCount > 0 ? 'lightgray' : undefined,
                        }}
                        onClick={() => {
                          if (ticketCount === 0) {
                            this.openDeleteModal()
                          }
                        }}
                      >
                        <Icon fa name="trash" />{' '}
                        <DynamicFormattedMessage
                          {...messages.dropdownTextDelete}
                        />
                        {ticketCount > 0 && (
                          <Fragment>
                            {' '}
                            (
                            <DynamicFormattedMessage
                              values={{ ticketCount }}
                              {...messages.usedInTickets}
                            />
                            )
                          </Fragment>
                        )}
                      </span>
                    }
                  />
                  <DropdownItem
                    label={
                      <span onClick={this.openUpdateModal}>
                        <Icon fa name="pencil-alt" />{' '}
                        <DynamicFormattedMessage
                          {...messages.dropdownTextUpdate}
                        />
                      </span>
                    }
                    onClick={this.openUpdateModal}
                  />
                </DropdownMenu>
              </Dropdown>
            </WithPermission>
          </div>
          <DeleteTicketTemplatePromptModal
            close={this.closeDeleteModal}
            deleteStatus={this.props.deleteStatus}
            isOpen={this.state.deleteModalOpen}
            onConfirm={this.onConfirmDelete}
          />
          <TicketTemplateSourceURIModal
            close={this.closeUpdateModal}
            isOpen={this.state.updateModalOpen}
            mutateticketTemplateResetError={
              this.props.updateTicketTemplateReset
            }
            mutateTicketTempaltesStatus={this.props.updateStatus}
            onSave={this.onSaveClick}
            update
          />
          <Properties
            ticketTemplateDetails={this.props.ticketTemplateDetails}
          />
        </BorderedCard>
        <div className="content">
          <NodeViewTabs>
            <div label="Manifest Details">
              <div className="tab-container">
                <ManifestDetails
                  ticketTemplateDetails={this.props.ticketTemplateDetails}
                />
              </div>
            </div>
          </NodeViewTabs>
        </div>
      </PageContainer>
    )
  }
}

TicketTemplateDetailsPage.propTypes = {
  // ~ bound action creators ~
  deleteTicketTemplate: PropTypes.func.isRequired,
  deleteTicketTemplateReset: PropTypes.func.isRequired,
  fetchTicketTemplateDetails: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  updateTicketTemplate: PropTypes.func.isRequired,
  updateTicketTemplateReset: PropTypes.func.isRequired,

  // ~ props from redux ~
  deleteStatus: ImmutablePropTypes.contains({
    loading: PropTypes.bool,
    error: PropTypes.bool,
  }),
  queryStringSrn: PropTypes.string.isRequired,
  ticketTemplateDetails: ImmutablePropTypes.contains({
    error: PropTypes.bool,
    loading: PropTypes.bool,
    data: ImmutablePropTypes.iterable,
  }),
  updateStatus: ImmutablePropTypes.contains({
    errors: ImmutablePropTypes.listOf(PropTypes.string),
    loading: PropTypes.bool,
  }),
}

const mapStateToProps = createStructuredSelector({
  deleteStatus: selectDeleteTicketTemplate,
  ticketTemplateDetails: selectTicketTemplateDetails,
  queryStringSrn: selectQueryStringSrn,
  updateStatus: selectUpdateTicketTemplate,
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      deleteTicketTemplate,
      deleteTicketTemplateReset,
      fetchTicketTemplateDetails,
      push,
      updateTicketTemplate,
      updateTicketTemplateReset,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const withReducer = injectReducer({ key: DOMAIN, reducer })
const withSaga = injectSaga({
  key: DOMAIN,
  saga: sagas,
  mode: DAEMON,
})

export default compose(
  withConnect,
  withReducer,
  withSaga
)(TicketTemplateDetailsPage)
