import { all, put, takeLatest } from 'redux-saga/effects'
import _ from 'lodash'
import { getClient } from 'apolloClient'

import {
  fetchTicketTemplateDetails as fetchTicketTemplateDetailsAction,
  deleteTicketTemplateError,
  deleteTicketTemplateSuccess,
  fetchTicketTemplateDetailsError,
  fetchTicketTemplateDetailsSuccess,
  updateTicketTemplateError,
  updateTicketTemplateSucces,
} from './actions'

import {
  DELETE_TICKET_TEMPLATE,
  FETCH_TICKET_TEMPLATE_DETAILS,
  UPDATE_TICKET_TEMPLATE,
} from './constants'

import {
  DELETE_TICKET_TEMPLATE_MUTATION,
  FETCH_TICKET_TEMPLATE_DETAILS_QUERY,
  UPDATE_TICKET_TEMPLATE_MUATION,
} from './queries'

import { processBotValidationError } from 'components/TicketTemplates/utils'

function* fetchTicketTemplateDetails({ payload }) {
  try {
    const client = yield getClient()
    const result = yield client.query({
      query: FETCH_TICKET_TEMPLATE_DETAILS_QUERY,
      variables: { srn: payload.srn },
    })

    let path = ['data', 'TicketTemplates', 'items', 0]
    const data = _.get(result, path, null)
    if (data === null) {
      throw new Error(`no results for path ${path.join(',')}`)
    }
    let ticketCount = _.get(result, ['data', 'Tickets', 'count'], null)
    if (ticketCount === null) {
      throw new Error('could not get tickets for template')
    }
    data.ticketCount = ticketCount
    yield put(fetchTicketTemplateDetailsSuccess({ data }))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('an error happened fetching ticket template details', e)
    yield put(fetchTicketTemplateDetailsError())
  }
}

function* deleteTicketTemplate({ payload }) {
  try {
    const client = yield getClient()
    const result = yield client.mutate({
      mutation: DELETE_TICKET_TEMPLATE_MUTATION,
      variables: { srn: payload.srn },
    })
    const path = ['data', 'DeleteTicketTemplate', 'srn']
    const data = _.get(result, path, null)
    if (data == null) {
      throw new Error(`no data at path $path`)
    }
    yield put(deleteTicketTemplateSuccess())
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('There was an error deleting the ticket template')
    yield put(deleteTicketTemplateError())
  }
}

function* updateTicketTemplate({ payload }) {
  try {
    const client = yield getClient()
    const result = yield client.mutate({
      mutation: UPDATE_TICKET_TEMPLATE_MUATION,
      variables: {
        srn: payload.srn,
        sourceURI: payload.sourceURI,
      },
    })
    if ((result.errors ?? []).length > 0) {
      const errors = processBotValidationError(result.errors[0])
      yield put(updateTicketTemplateError(errors))
    } else {
      yield all([
        put(updateTicketTemplateSucces()),
        put(fetchTicketTemplateDetailsAction({ srn: payload.srn })),
      ])
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`an excepeiton happened creating ticket template`, e)
    yield put(updateTicketTemplateError(['unknown.error']))
  }
}

function* saga() {
  yield all([
    takeLatest(FETCH_TICKET_TEMPLATE_DETAILS, fetchTicketTemplateDetails),
    takeLatest(DELETE_TICKET_TEMPLATE, deleteTicketTemplate),
    takeLatest(UPDATE_TICKET_TEMPLATE, updateTicketTemplate),
  ])
}

export default saga
