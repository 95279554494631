import React from 'react'
import PropTypes from 'prop-types'
import ImmutableProptypes from 'react-immutable-proptypes'
import moment from 'moment'

import styled from 'styled-components'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import TextLink from 'components/TextLink'

import messages from '../messages'

const PropertiesContainer = styled.div`
  display: flex;

  .label {
    font-size: 1rem;
    font-weight: 400;
    color: lightgray;
    padding-bottom: 0.25rem;
  }
  .property {
    padding: 0.5rem 1rem;
  }
`

export default function TicketTemplateProperties(props) {
  if (!props.ticketTemplateDetails.get('data')) {
    // if this happens, we are in BIG trouble!!
    return <span>Error</span>
  }

  const ticketTemplate = props.ticketTemplateDetails.get('data').toJS()

  const isCustom = ticketTemplate.org === 'supersonrai'
  return (
    <PropertiesContainer>
      <div className="property">
        <div className="label">
          <DynamicFormattedMessage {...messages.labelOwner} />
        </div>
        <div>{isCustom ? 'Sonrai' : 'Custom'}</div>
      </div>
      <div className="property">
        <div className="label">
          <DynamicFormattedMessage {...messages.labelSource} />
        </div>
        <TextLink href={ticketTemplate.sourceURI} target="_blank">
          <DynamicFormattedMessage {...messages.labelURL} />
        </TextLink>
      </div>
      <div className="property">
        <div className="label">
          <DynamicFormattedMessage {...messages.labelLastModified} />
        </div>
        <div>
          {moment(
            ticketTemplate.lastModified || ticketTemplate.createdDate
          ).format('ll')}
        </div>
      </div>
    </PropertiesContainer>
  )
}

TicketTemplateProperties.propTypes = {
  ticketTemplateDetails: ImmutableProptypes.contains({
    data: ImmutableProptypes.contains({
      createdDate: PropTypes.any,
      lastModified: PropTypes.any,
      org: PropTypes.string,
      sourceURI: PropTypes.string,
    }),
  }),
}
