import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List } from 'immutable'

import styled from 'styled-components'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'

import { createErrorMessageArgs } from './utils'

import messages from './messages'

const ErrorListContainer = styled.ul`
  list-style-type: circle;
  margin-top: 8px;
  margin-left: 24px;
`
const ErrorMessageContainer = styled.li``

export function TemplateModalError(props) {
  const warnStyle = { color: props.theme.warn }
  const errorStyle = { color: 'red' }

  return (
    <ErrorListContainer>
      {props.sourceURIValidation.isWarn && (
        <ErrorMessageContainer style={warnStyle}>
          <DynamicFormattedMessage
            style={warnStyle}
            {...messages[`error.${props.sourceURIValidation.message}`]}
          />
        </ErrorMessageContainer>
      )}
      {!props.sourceURIValidation.isValid && (
        <ErrorMessageContainer style={errorStyle}>
          <DynamicFormattedMessage
            style={errorStyle}
            {...messages[`error.${props.sourceURIValidation.message}`]}
          />
        </ErrorMessageContainer>
      )}
      {(props.mutateTicketTempaltesStatus.get('errors') ?? List()).map(
        errorMessage => {
          const { key, values } = createErrorMessageArgs(errorMessage)
          return (
            <ErrorMessageContainer
              key={`template_mutation_error_${errorMessage}`}
              style={errorStyle}
            >
              <DynamicFormattedMessage
                style={errorStyle}
                values={values}
                {...messages[`error.${key}`]}
              />
            </ErrorMessageContainer>
          )
        }
      )}
    </ErrorListContainer>
  )
}

TemplateModalError.propTypes = {
  sourceURIValidation: PropTypes.shape({
    isValid: PropTypes.bool,
    isWarn: PropTypes.bool,
    message: PropTypes.string,
  }),
  mutateTicketTempaltesStatus: ImmutablePropTypes.contains({
    errors: ImmutablePropTypes.listOf(PropTypes.string),
  }),
  theme: themeShape,
}

export default themeable(TemplateModalError)
