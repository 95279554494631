import React, { Fragment } from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'

import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'
import Button from 'components/Button'
import TextLink from 'components/TextLink'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import Icon from 'components/Icon'

import messages from '../messages'

export default function DeleteTicketTemplateProptModal(props) {
  return (
    <Modal isOpen={props.isOpen}>
      <ModalHeader>
        <DynamicFormattedMessage {...messages.deleteConfirmModalTitle} />
      </ModalHeader>
      <ModalBody>
        <DynamicFormattedMessage {...messages.deleteConfirmModalBody} />
        {props.deleteStatus.get('error') && (
          <Fragment>
            <br />
            <span style={{ color: 'red' }}>
              <DynamicFormattedMessage {...messages.deleteError} />
            </span>
          </Fragment>
        )}
      </ModalBody>
      <ModalFooter>
        <TextLink color="primary" onClick={props.close}>
          <DynamicFormattedMessage {...messages.cancel} />
        </TextLink>
        <Button
          color="primary"
          onClick={props.onConfirm}
          disabled={props.deleteStatus.get('loading')}
        >
          {props.deleteStatus.get('loading') && <Icon fa spin name="sync" />}
          <DynamicFormattedMessage {...messages.confirm} />
        </Button>
      </ModalFooter>
    </Modal>
  )
}

DeleteTicketTemplateProptModal.propTypes = {
  close: PropTypes.func.isRequired,
  deleteStatus: ImmutablePropTypes.contains({
    loading: PropTypes.bool,
    error: PropTypes.bool,
  }),
  isOpen: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
}
