import { defineMessages } from 'react-intl'

export default defineMessages({
  cancel: {
    id: 'app.containers.TicketTemplatesPage.cancel',
    defaultMessage: 'Cancel',
  },
  createModalHeader: {
    id: 'app.containers.TicketTemplatesPage.createModalHeader',
    defaultMessage: 'Create Custom Ticket Template',
  },
  'error.filename.warn': {
    id: 'app.containers.TicketTemplatesPage.error.filename.warn',
    defaultMessage: 'A file in this directory named manifest.yaml will be used',
  },
  'error.invalid.url': {
    id: 'app.containers.TicketTemplatesPage.error.invalid.url',
    defaultMessage: 'This URL is invalid.',
  },
  'error.invalid.filename': {
    id: 'app.containers.TicketTemplatesPage.error.invalid.filename',
    defaultMessage: 'The filename must be manifest.yaml',
  },
  'error.title.required': {
    id: 'app.containers.TicketTemplatesPage.error.title.required',
    defaultMessage: `Property 'title' is required`,
  },
  'error.fields.name.required': {
    id: 'app.containers.TicketTemplatesPage.error.fields.name.required',
    defaultMessage: `Property 'name' is required for field at position {position}`,
  },
  'error.fields.name.duplicate': {
    id: 'app.containers.TicketTemplatesPage.error.fields.name.required',
    defaultMessage: `Field at position {position} is has the same name as a field that is already defined`,
  },
  'error.fields.type.required': {
    id: 'app.containers.TicketTemplatesPage.error.fields.type.required',
    defaultMessage: `Property 'type' is required for field at position {position}`,
  },
  'error.fields.type.invalid': {
    id: 'app.containers.TicketTemplatesPage.error.fields.type.invalid',
    defaultMessage: `Property 'type' of field at position {position} is invalid`,
  },
  'error.fields.select.required': {
    id: 'app.containers.TicketTemplatesPage.error.fields.select.required',
    defaultMessage: `Field at position {position} requires definition of select`,
  },
  'error.fields.select.search': {
    id: 'app.containers.TicketTemplatesPage.error.fields.select.required',
    defaultMessage: `Property 'serch' of search defintion of field field at position {position} is required`,
  },
  'error.unknown.error': {
    id: 'app.containers.TicketTemplatesPage.error.unknown.error',
    defaultMessage: `An unknown error occurred`,
  },
  sourceURI: {
    id: 'app.containers.TicketTemplatesPage.sourceURI',
    defaultMessage: 'Source URI',
  },
  save: {
    id: 'app.containers.TicketTemplatesPage.save',
    defaultMessage: 'Save',
  },
  updateModalHeader: {
    id: 'app.containers.TicketTemplates.updateModalHeader',
    defaultMessage: 'Update Custom Ticket Template',
  },
})
