import gql from 'graphql-tag'

export const FETCH_TICKET_TEMPLATE_DETAILS_QUERY = gql`
  query fetchTicketTemplateDetails($srn: String) {
    TicketTemplates(where: { srn: { value: $srn } }) {
      items {
        sourceURI
        title
        description
        version
        authorName
        authorEmail
        srn
        resourceId
        createdBy
        createdDate
        lastModifiedBy
        lastModifiedDate
        sourceContent
        orgName
        fields {
          name
          required
          type
          select {
            search
            path
            labelFields
            valueField
          }
        }
      }
    }
    Tickets(where: { templateSRN: { value: $srn } }) {
      count
    }
  }
`

export const DELETE_TICKET_TEMPLATE_MUTATION = gql`
  mutation deleteTicketTemplateMutation($srn: String) {
    DeleteTicketTemplate(input: { srn: $srn }) {
      srn
    }
  }
`

export const UPDATE_TICKET_TEMPLATE_MUATION = gql`
  mutation updateTicketTemplate($srn: String!, $sourceURI: String!) {
    UpdateTicketTemplate(input: { srn: $srn, sourceURI: $sourceURI }) {
      srn
    }
  }
`
