import React from 'react'
import PropTypes from 'prop-types'
import ImmutableProptypes from 'react-immutable-proptypes'

import styled from 'styled-components'
import BorderedCard from 'components/BorderedCard'
import CopyToClipboard from 'components/CopyToClipboard'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import YamlView from 'components/YamlView'

import mesages from '../messages'

const Container = styled.div`
  display: grid;
  grid-template-columns: 300px auto;
  height: calc(100vh - 320px);
  min-height: 400px;
  position: relative;

  .label {
    font-size: 1rem;
    font-weight: 400;
    color: lightgray;
    padding-bottom: 0.25rem;
  }
  .property {
    padding: 0.5rem 1rem;
  }

  .details-container {
    position: relative;
    height: calc(100% - 16px);
    overflow: hidden;

    .copy-control {
      position: absolute;
      right: 6px;
      top: 20x;
    }

    .yaml-view-container {
      border: 1px solid lightgray;
      height: calc(100% - 25px);
      padding: 4px;
      overflow: scroll;
    }
  }
`

export default function TicketTemplateManifestDetails(props) {
  if (!props.ticketTemplateDetails.get('data')) {
    // if this happens, we are in BIG trouble!!
    return <span>Error</span>
  }

  const ticketTemplate = props.ticketTemplateDetails.get('data').toJS()

  return (
    <BorderedCard>
      <Container>
        <div className="properties-container">
          <div className="property">
            <div className="label">
              <DynamicFormattedMessage {...mesages.labelTitle} />
            </div>
            <div>{ticketTemplate.title}</div>
          </div>
          <div className="property">
            <div className="label">
              <DynamicFormattedMessage {...mesages.labelDescription} />
            </div>
            <div>{ticketTemplate.description}</div>
          </div>
          <div className="property">
            <div className="label">
              <DynamicFormattedMessage {...mesages.labelVersion} />
            </div>
            <div>{ticketTemplate.version}</div>
          </div>
          <div className="property">
            <div className="label">
              <DynamicFormattedMessage {...mesages.labelAuthorName} />
            </div>
            <div>{ticketTemplate.authorName}</div>
          </div>
          <div className="property">
            <div className="label">
              <DynamicFormattedMessage {...mesages.labelAuthorEmail} />
            </div>
            <div>{ticketTemplate.authorEmail}</div>
          </div>
        </div>
        <div className="details-container">
          <div className="label">
            <DynamicFormattedMessage {...mesages.labelSourceContent} />
          </div>
          <div className="yaml-view-container">
            <div className="copy-control">
              <CopyToClipboard value={ticketTemplate.sourceContent || ''} />
            </div>
            <YamlView value={ticketTemplate.sourceContent || ''} skipParsing />
          </div>
        </div>
      </Container>
    </BorderedCard>
  )
}

TicketTemplateManifestDetails.propTypes = {
  ticketTemplateDetails: ImmutableProptypes.contains({
    data: ImmutableProptypes.contains({
      createdDate: PropTypes.any,
      lastModified: PropTypes.any,
      org: PropTypes.string,
      sourceURI: PropTypes.string,
    }),
  }),
}
